import React, { useState } from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import { usePub } from "../Event";
import { AuditorIcon, ArcIcon, ConsoleLogo, ConsoleIcon, ProfilePic, NeumetricLogo, DocumentorIcon, ControllerIcon, EducatorIcon, InvestigatorIcon, ContinuerIcon, CentralIcon } from "../Assets";
import "./style.scss";

const DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;
const IS_LOCALHOST = process.env.REACT_APP_LOCALHOST || 'FALSE';

const ExcludedPaths = ['/profile', '/map-external-user']

const Modules = [
  { moduleId: 'auditor', Component: AuditorIcon, label: 'Auditor' },
  { moduleId: 'arc', Component: ArcIcon, label: 'Arc' },
  { moduleId: 'console', Component: ConsoleIcon, label: 'Console' },
  { moduleId: 'controller', Component: ControllerIcon, label: 'Controller', isAdmin: true },
  { moduleId: 'documenter', Component: DocumentorIcon, label: 'Documenter' },
  { moduleId: 'educator', Component: EducatorIcon, label: 'Educator' },
  { moduleId: 'investigator', Component: InvestigatorIcon, label: 'Investigator' },
  { moduleId: 'continuer', Component: ContinuerIcon, label: 'Continuer' },
  { moduleId: 'central', Component: CentralIcon, label: 'Central' }
]

const NavItem = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isActive = (pathname === props.link) || (pathname.indexOf(props.link) > -1);
  const onClick = (e) => {
    e.preventDefault();
    navigate(props.link)
  }
  return (
    <a onClick={onClick} className={`${props.font || 'h4'} exo2 item ${isActive ? 'active' : ''}`}>
      {props.label}
    </a>
  )
}

const CompanyLogo = (props) => {
  const { user } = props
  let [error, setError] = useState(false);
  return (
    <>
      {
        error ?
          <NeumetricLogo alt='company Logo' className='company' />
          :
          <img src={'https:' + (user.logo_url ? user.logo_url : (user.ent_org && user.ent_org.logo_url ? user.ent_org.logo_url : ''))} alt='company Logo' className='company' onError={() => setError(true)} />
      }
    </>
  )
}

export const NavItems = (props) => {
  const { items, font } = props
  return (
    items.map((_item) => {
      return (
        <NavItem key={_item.label} font={font} {..._item} />
      )
    })
  )
}
export const getModuleURL = (mod) => {
  return `https://${mod}.${DOMAIN}`
}
export const getModuleLink = (moduleId, user, index) => {
  const sessionToken = user.session_token;
  let origin = '';
  if (moduleId === 'localhost') {
    origin = "http://localhost:3000"
  } else {
    origin = IS_LOCALHOST === 'TRUE' ? `http://localhost:300${(index || 0)}` : `https://${moduleId}.${DOMAIN}`
  }
  return `${origin}/login?sessionToken=${sessionToken}`;
}
export const AppNavBar = (props) => {
  const publish = usePub();
  const { isAdmin, isArchitect, currentModule } = props
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const items = props.items;
  // const subscribedModules = getSubscribedModules(props.user ? props.user.modules : []);
  const notFound = (items.findIndex((_) => pathname.indexOf(_.link) > -1) === -1);
  const isProfile = pathname === '/profile'
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = (e) => {
    e.preventDefault();
    publish('SESSION_LOGOUT');
  }
  const getSubscribedModules = () => {
    let modules = Array.from(Modules);
    modules = modules.filter((mod) => {
      return mod.isAdmin ? (isAdmin || isArchitect) : mod.moduleId !== currentModule;
    })
    modules.sort((modA, modB) => {
      let moduleIdA = String(modA.moduleId).toLowerCase(),
        moduleIdB = String(modB.moduleId).toLowerCase();
      return moduleIdA.localeCompare(moduleIdB)
    })
    return modules
  }
  return (
    <nav className={`w-100 row navbar o-hide ${props.className || ''}`}>
      <a href="/"><ConsoleLogo alt='App Logo' height="65" className='org-logo' /></a>
      <div className='row flex-full f-rest o-hide'>
        <div className="row w-100 o-hide">
          <div className={`row space-btn items f-rest`}>
            {
              (!notFound || isProfile) &&
              <NavItems items={items} />
            }
          </div>
          {
            Boolean(props.user) &&
            <>
              <div className='row space-end account-options'>
                <div className='row account-icon' onClick={handleClick} >
                  <ProfilePic alt='profile' height={50} />
                  <ArrowDropDownIcon className="dropdown" fontSize='large' sx={{ color: '#073C3C', height: '2em' }} />
                </div>
                <CompanyLogo user={props.user} />
              </div>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <div className='col profile-menus'>
                  <div className='btn w-100 row btn-profile v-start no-border'>
                    <ProfilePic className='profile-img' />
                    <div className='col f-rest usename'>
                      <div className='col w-100'>
                        <span className='f20 reg'>{props.user.firstname} {props.user.lastname}</span>
                        <span className='f14 reg c1155CC'>{props.user.email}</span>
                      </div>
                      <div className='row h-btn profile-signout w-100'>
                        <Link to="/profile" className='f16 profile' >Profile</Link>
                        <Link className='f16 logout' onClick={logout}>Sign Out</Link>
                      </div>
                    </div>
                  </div>
                  {
                    getSubscribedModules().map(({ label, Component, moduleId }, i) => {
                      return (
                        <a className='row v-ctr f16 menu-entry link c00085' key={moduleId} href={getModuleLink(moduleId, props.user, i)} >
                          <Component className="mod-logo" />
                          <span>{label}</span>
                        </a>
                      )
                    })
                  }
                </div>
              </Menu>
            </>
          }
        </div>
      </div>
    </nav>
  )
}
