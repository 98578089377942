import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Screen, HelpSection } from "../../components";
import { OrgDetails } from "./Components/Details";
import { OrgReset } from "./Components/OrgReset";
import { orgActions, getCurrentUserOrg, getCurrentUser, isArchitect, isAdmin, isOrganizer } from "../../store";
import "./style.scss";

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const org = useSelector(getCurrentUserOrg)
  const user = useSelector(getCurrentUser);
  useEffect(() => {
    dispatch(orgActions.getUserOrg())
  }, [])
  const onEdit = () => {
    navigate("/O/edit")
  }
  const handleUserUpdate = (userId) => {
    navigate(`/U/${userId}/edit`)
  }
  useEffect(() => {
    if (org === 'NotExist') {
      navigate("/O/add")
    }
  }, [org])
  return (
    <Screen className='org-det w-100 h-100 oy-auto'>
      <Grid container spacing={0} classes={{ root: "f-rest" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {
            org === null ?
              <CircularProgress />
              : org !== 'NotExist' ?
                <React.Fragment>
                  {
                    isOrganizer(user) &&
                    <div className='col reset-sec'>
                      <OrgReset org={org} />
                    </div>
                  }
                  <OrgDetails
                    className='orgd'
                    org={org}
                    showEdit={isAdmin(user)}
                    onUpdateUser={handleUserUpdate}
                    enableEditOrgUser={isAdmin(user) || isOrganizer(user) || isArchitect(user)}
                    onEdit={onEdit} />
                </React.Fragment>
                : null

          }
        </Grid>
      </Grid>
    </Screen>
  )
}