import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { PrimaryButton } from "../../../components";
import TextButton from '@mui/material/Button';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Popover, Typography, TextField } from "@mui/material";
import { orgActions, getResetCaptcha } from "../../../store";
import { LockReset, HighlightOff } from '@mui/icons-material';

const Envirnoment = process.env.REACT_APP_ENV

export const OrgReset = (props) => {
  const { org } = props;
  const dispatch = useDispatch();
  const captcha = useSelector(getResetCaptcha)
  const [state, setState] = React.useState({ anchorResetEl: null, openResetDialog: false, orgValue: '', captchaValue: '' })
  const handleDisplayResetDropdown = (event) => {
    setState((_) => ({ ..._, anchorResetEl: event.currentTarget }))
  }
  const getCaptcha = () => {
    dispatch(orgActions.fetchCaptchaForReset())
  }
  const handleResetDropdownClose = () => {
    setState((_) => ({ ..._, anchorResetEl: null }))
  };
  const handleResetDialogOpen = () => {
    setState((_) => ({ ..._, openResetDialog: true }))
    getCaptcha()
  }
  const handleResetDialogClose = () => {
    setState((_) => ({ ..._, openResetDialog: false, anchorResetEl: null, captchaValue: '', orgValue: '' }))
  };
  const handleReset = () => {
    dispatch(orgActions.verifyCaptchaEmailDomain({ "captcha": state.captchaValue, "email_domain": state.orgValue, orgId: org.id }))
    setState((_) => ({ ..._, openResetDialog: false, anchorResetEl: null, captchaValue: '', orgValue: '' }))
  }
  const handleCaptchaChange = (e) => {
    setState((_) => ({ ..._, captchaValue: e.target.value }))
  }
  const handleOrgChange = (e) => {
    setState((_) => ({ ..._, orgValue: e.target.value }))
  }

  const org_email_domains = org.email_domain.split(',')
  const isTextButtonActive = state.captchaValue.trim() !== '' && state.captchaValue === captcha && state.orgValue.trim() !== '' && org_email_domains.includes(state.orgValue);
  const open = Boolean(state.anchorResetEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <React.Fragment>
      {
        Envirnoment === 'DEMO' &&
        <React.Fragment>
          <div className='row h-end'>
            <PrimaryButton font='h10' text='...' color='blue' onClick={handleDisplayResetDropdown} />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={state.anchorResetEl}
            onClose={handleResetDropdownClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Typography sx={{ px: 3, py: 1.5 }}>
              <div className='v-ctr row cFF3333 h12 exo2 bold' onClick={handleResetDialogOpen}>
                <LockReset className='mr-1' /> Reset
              </div>
            </Typography>
          </Popover>
          {
            state.openResetDialog &&
            <Dialog
              open={open}
              onClose={handleResetDialogClose}
              PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries(formData.entries());
                  const email = formJson.email;
                  // console.log(email);
                  handleResetDialogClose();
                },
              }}
            >
              <DialogTitle className='cFF3333 f18 exo2 bold'>Reset to Default?</DialogTitle>
              <DialogContent>
                <DialogContentText className=''>
                  <div className='c00 f16'>This action will reset this organisation to its default state.<br />Are you sure that you want to proceed?</div>
                  <div className='row h-ctr mt-1 h6 exo2'> {captcha} </div>
                  <div className='row h-btn v-ctr c00 mt-1 mr-1 f16'>Enter the characters displayed above
                    <div className='ml-1'><TextField variant="outlined" InputProps={{ style: { fontSize: '1.4rem' } }} onChange={handleCaptchaChange} /></div>
                  </div>
                  <div className='row h-btn v-ctr c00 mt-1 mr-1 f16'>Enter the organisation's primary domain
                    <div className='ml-1'><TextField variant="outlined" InputProps={{ style: { fontSize: '1.4rem' } }} onChange={handleOrgChange} /></div>
                  </div>
                  <hr className='mt-1' />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <div style={{ marginTop: '-20px', paddingRight: "25px" }} className='row'>
                  <TextButton
                    variant="text"
                    startIcon={<LockReset />}
                    className={`h12 exo2 ${isTextButtonActive ? 'cFF3333' : ''}`}
                    disabled={!isTextButtonActive}
                    onClick={handleReset}
                    style={{ textTransform: 'none', fontFamily: 'Exo 2' }}
                  >Reset
                  </TextButton>
                  <div className='mr-1'></div>
                  <PrimaryButton font='h12' className='exo2' startIcon={<HighlightOff />} text='Cancel' color='blue' onClick={handleResetDialogClose} />
                </div>
              </DialogActions>
            </Dialog>
          }
        </React.Fragment>
      }
    </React.Fragment>
  )
}