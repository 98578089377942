import React, { useEffect, useState, useRef } from 'react';
import { SetPasswordOvalImg, ConsoleLogo, NeumetricLogo, Input, Button, ConfirmationBox, ToastAlert, FusionLogo } from "../../components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Divider, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { userActions, getNewJoinUser, isLoading, getAlert, commonAction, KEY_NM_API } from "../../store";
import "./style.scss";

const PASSWORD_REGEX = new RegExp("(?=.{9,})(?=.*?[^\w\s])(?=.*?[0-9])(?=.*?[A-Z]).*?[a-z].*");
const Policies = [
  { label: 'Must be nine (9) characters or more long', key: 'LEN' },
  { label: 'Contain at least one (1) Uppercase character (ABCD...)', key: 'UPPERCASE' },
  { label: 'Contain at least one (1) Lowercase character (abcd...)', key: 'LOWERCASE' },
  { label: 'Contain at least one (1) Numeric character (0123...)', key: 'NUM' },
  { label: 'Contain at least one (1) Special character (@#$%...)', key: 'SPECIAL' }
]
export default (props) => {
  const _timerRef = useRef(null)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notification = useSelector(getAlert);
  const user = useSelector(getNewJoinUser);
  const loading = useSelector(isLoading);
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState({ email: '', password: '', confirmPassword: '', error: {}, invalid: false });
  useEffect(() => {
    document.title = 'Console | Fusion Demo';
    const token = searchParams.get('reset_password_token');
    if (token) {
      dispatch(userActions.fetchResetTokenUser({ token: token }))
    } else {
      navigate("/login")
    }
  }, [])
  useEffect(() => {
    if (notification) {
      let autoClose = notification.autoClose === undefined ? true : notification.autoClose;
      if (autoClose) {
        _timerRef.current = setTimeout(closeConfirmation, 3000);
      }
    }
  }, [notification])

  useEffect(() => {
    if (user && user !== 'Invalid') {
    setState((_) => ({ ..._, email: user.email }));
    if (user.resetPasswordSuccess) {
    navigate("/")
    }
    } else if (user && user === 'Invalid') {
    navigate("/page-not-found")
    }
  }, [user])
  const closeConfirmation = () => {
    if (_timerRef.current) {
      clearTimeout(_timerRef.current);
    }
    dispatch(commonAction.setAlert(null));
  }
  const onValueChange = (e) => {
    const { name, value } = e.target;
    setState((_) => ({ ..._, [name]: value, error: { ..._.error, [name]: false } }));
  }
  const onLogInSubmit = (e) => {
    e.preventDefault();
    let err = {}, message = '';
    if (state.password === '') {
      err.password = 'required';
      message = 'Password is required'
    } else if (state.confirmPassword === '') {
      err.confirmPassword = 'required';
      message = 'Confirm Password is required'
    } else if (state.password !== state.confirmPassword) {
      err.confirmPassword = 'Password mismatch';
      message = 'Password and confirm password is not matching'
    } else if (state.password.length < 9 || !PASSWORD_REGEX.test(state.password)) {
      err.confirmPassword = 'Password mismatch';
      message = 'Password is not matching the policy'
    }
    if (Object.keys(err).length > 0) {
      setState((_) => ({ ..._, error: { ..._.error, ...err } }));
      dispatch(commonAction.setAlert({ success: false, title: message }))
      return;
    } const header = { [KEY_NM_API]: user.api_key }
    dispatch(userActions.resetNewUserPassword({ email: state.email, password: state.password, header }))
  }
  return (
    <div className='bg-primary reset-page row w-100 h-100 h-ctr v-ctr'>
      {
        user ?
          <>
            {
              user !== 'Invalid' &&
              <div className='row card'>
                <div className='col f-rest form-sec'>
                  <h1 className='f30 bold exo2 c-primary'>Set Password</h1>
                  <h1 className='h11 c-primary'>Set a password for your Fusion Account.</h1>
                  <form className='form col h-ctr v-ctr' autoComplete='off' onSubmit={onLogInSubmit} >
                    <Input
                      type="password"
                      className='h8'
                      name='password'
                      placeholder="Type New Password"
                      value={state.password}
                      error={state.error.password}
                      onChange={onValueChange} />
                    <Input
                      type="password"
                      className='h8'
                      name='confirmPassword'
                      placeholder="Retype Password"
                      value={state.confirmPassword}
                      error={state.error.confirmPassword}
                      onChange={onValueChange} />
                    <Button disableElevation loading={loading} text='Submit' type="submit" color='#1177CC' className='h8 bold' />
                  </form>
                  <div className='board'>
                    <h6 className='h9 bold c-primary'>Password Policy</h6>
                    <ul>
                      {
                        Policies.map((policy, i) => {
                          return <li className='h10 c-primary' key={policy.key}>{policy.label}</li>
                        })
                      }
                    </ul>
                  </div>
                </div>
                <SetPasswordOvalImg className="oval" alt="login oval" />
                <FusionLogo  className="logo" />
              </div>
            }
          </>
          :
          <div className='col w-100 h-100 h-ctr v-ctr'>
            <CircularProgress />
          </div>
      }
      {
        Boolean(notification) &&
        <React.Fragment>
          {
            notification.severity === 'success' ?
              <ConfirmationBox
                {...notification}
                btnOK={{ onClick: closeConfirmation }}
              />
              :
              <ToastAlert title={notification.title} onClose={closeConfirmation} />
          }
        </React.Fragment>
      }
    </div>
  )
}

